import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IApplication } from '@model/interfaces/application';
import { ApplicationService, ICurrentSectionAndStatus } from './services/application.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FormSections } from '@model/enums/form-sections.enum';
import { Subject, Subscription } from 'rxjs';
import { safeDetectChanges } from '../common/safe-detect-changes.library';
import { DeniedModalService } from '../common/modals/denied-modal.service';
import { PreScreeningService } from '../../admin-portal/common/services/pre-screening.service';
import { ApplicationIssueService } from './services/application-issue.service';
import { ApplicationWhoAreYouService } from './services/application-who-are-you.service';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationFamilyHistoryService } from './services/application-family-history.service';
import { ViewportScroller } from '@angular/common';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { filter } from 'rxjs/operators';
import { IUser } from '@model/interfaces/user';

declare let gtag: Function;

@Component({
    selector: 'app-application',
    styles: [],
    templateUrl: 'donor-application.component.html',
})
export class DonorApplicationComponent implements OnInit {
    application: IApplication;
    donor: ILoggedIn;
    user: IUser;
    completeString = 'Complete';

    section1Finalized: boolean;
    section2Finalized: boolean;
    section3Finalized: boolean;
    section4Finalized: boolean;
    allSectionsFinalzied: boolean;

    isPreScreeningComplete: Subject<boolean> = new Subject();
    isMedicalHistoryComplete: Subject<boolean> = new Subject();
    isWhoAreYouComplete: Subject<boolean> = new Subject();
    isFamilyHistoryComplete: Subject<boolean> = new Subject();

    private _denied = false;
    get denied(): boolean {
        return this._denied;
    }
    set denied(val: boolean) {
        if (!this.router.url.includes('login') && val) {
            this.deniedModalService.showModal(this.application.DenialReasonId);
        }
        this._denied = val;
    }
    private _currentSection: ICurrentSectionAndStatus;
    set currentSection(val: ICurrentSectionAndStatus) {
        if (this.denied) {
            val.status = 'Denied';
        }
        this._currentSection = val;
    }
    get currentSection(): ICurrentSectionAndStatus {
        return this._currentSection;
    }

    subscription: Subscription = new Subscription();

    @ViewChild('formTabs') tabSet: NgbTabset;

    tabNames = {
        preScreening: 'tab-preScreening',
        familyHistory: 'tab-familyHistory',
        medicalIssues: 'tab-medicalIssues',
        whoAreYou: 'tab-whoAreYou',
    };
    tabsLoaded: boolean;
    tabNameSectionNameMap = {
        'tab-preScreening': 'Pre-screening',
        'tab-medicalIssues': 'Medical Issues',
        'tab-familyHistory': 'Who Are You?',
        'tab-whoAreYou': 'Who Are You?',
    };

    activeTab = 'tab-medicalIssues';
    constructor(
        private applicationService: ApplicationService,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private deniedModalService: DeniedModalService,
        private cdr: ChangeDetectorRef,
        private preScreeningService: PreScreeningService,
        private applicationIssueService: ApplicationIssueService,
        private applicationWhoAreYouService: ApplicationWhoAreYouService,
        private applicationFamilyHistoryService: ApplicationFamilyHistoryService,
        private scroller: ViewportScroller,
        private pageTitleService: PageTitlesService,
    ) { }

    ngOnInit(): void {
        this.pageTitleService.setPageTitle(`The World Egg and Sperm Bank - Application`);
        this.tabsLoaded = false;
        this.donor = this.authService.currentUser.getValue();
        this.subscription.add(
            this.applicationService.currentSectionAndStatus.subscribe((val: ICurrentSectionAndStatus) => {
                this.currentSection = val;
                safeDetectChanges(this.cdr);
            }),
        );
        this.applicationService.getPendingApplication().subscribe((application) => {
            this.application = application;
            this.setApplicationStatus();
            this.denied = this.application.DenialReasonId > 0;
            if (this.allSectionsFinalzied && !this.denied) {
                this.router.navigate(['application', 'submit']);
            }
        });
        this.setUpConversionTracker();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setApplicationStatus(): void {

        this.section1Finalized = this.application.ApplicationPreScreening.Complete && this.application.ApplicationPreScreening.Submitted;
        this.section2Finalized = this.application.ApplicationIssue.Complete && this.application.ApplicationIssue.Submitted;
        this.section3Finalized = this.application.ApplicationFamilyHistory.Complete && this.application.ApplicationFamilyHistory.Submitted;
        this.section4Finalized = this.application.ApplicationWhoAreYou.Complete && this.application.ApplicationWhoAreYou.Submitted;

        this.allSectionsFinalzied =
            this.section1Finalized && this.section2Finalized && this.section3Finalized && this.section4Finalized;
    }

    // called from html
    getInitialTab(): string {
        if (this.tabSet && this.tabsLoaded && !this.denied) {
            return this.tabSet.activeId;
        }
        let tab = this.tabNames.medicalIssues;
        if (!this.section1Finalized) {
            tab = this.tabNames.preScreening;
        } else if (!this.section2Finalized) {
            tab = this.tabNames.medicalIssues;
        } else if (!this.section3Finalized) {
            tab = this.tabNames.familyHistory;
        } else if (!this.section4Finalized) {
            tab = this.tabNames.whoAreYou;
        }
        this.tabsLoaded = this.tabSet !== null || this.tabSet !== undefined;
        return tab;
    }

    validateForm(): void {

        if (this.currentSection.sectionId === FormSections.PreScreening) {
            this.preScreeningService.updatePreScreening(this.application.ApplicationPreScreening).subscribe((answer) => {
                this.isPreScreeningComplete.next(answer.PreScreeningComplete && answer.PreScreeningSubmitted != null);
                this.application.ApplicationPreScreening.Complete = answer.PreScreeningComplete;
                this.application.ApplicationPreScreening.Submitted = answer.PreScreeningSubmitted;
                this.onSectionValidated(answer);
            });
        }

        if (this.currentSection.sectionId === FormSections.MedicalIssues) {
            this.applicationIssueService.updateWithFks(this.application.ApplicationIssue).subscribe((answer) => {
                this.isMedicalHistoryComplete.next(answer.MedicalIssuesComplete && answer.MedicalIssuesSubmitted != null);
                this.application.ApplicationIssue.Complete = answer.MedicalIssuesComplete;
                this.application.ApplicationIssue.Submitted = answer.MedicalIssuesSubmitted;
                this.onSectionValidated(answer);
            });
        }

        if (this.currentSection.sectionId === FormSections.FamilyHistory) {
            this.applicationFamilyHistoryService.updateWithFks(this.application.ApplicationFamilyHistory).subscribe((answer) => {
                this.isFamilyHistoryComplete.next(answer.FamilyHistoryComplete && answer.FamilyHistorySubmitted != null);
                this.application.ApplicationFamilyHistory.Complete = answer.FamilyHistoryComplete;
                this.application.ApplicationFamilyHistory.Submitted = answer.FamilyHistorySubmitted;
                this.onSectionValidated(answer);
            });
        }

        if (this.currentSection.sectionId === FormSections.WhoAreYou) {
            this.applicationWhoAreYouService.updateWhoAreYouEtc(this.application.ApplicationWhoAreYou, this.user).subscribe((answer) => {
                this.isWhoAreYouComplete.next(answer.WhoAreYouComplete && answer.WhoAreYouSubmitted != null);
                this.application.ApplicationWhoAreYou.Complete = answer.WhoAreYouComplete;
                this.onSectionValidated(answer);
            });
        }
    }

    UserChanged(updatedUser: IUser): void {
        this.user = updatedUser;
    }

    onSectionValidated(answer: any): void {
        this.success();
        this.denied = answer.Denied;
        this.setApplicationStatus();
        this.scroller.scrollToPosition([0, 0]);
        this.cdr.detectChanges();
        if (!this.denied) {
            this.applicationService.applicationSaved$.next(true);
        }
    }

    success(): void {
        this.currentSection.formGroup.markAsPristine();
        this.notificationService.success('Application saved successfully!');
    }

    selectTabs(tabName: string): void {
        if (this.tabSet) {
            this.tabSet.select(tabName);
        }
    }

    private setUpConversionTracker(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            gtag('event', 'conversion', { send_to: 'AW-851385468/m_56CLLe7ZADEPy4_JUD' });
        });
    }
}
