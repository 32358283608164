<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <div class="form-section-border">
        <h3>Personal Information</h3>
    </div>
    <form *ngIf="metaData" class="miles-form padded row application-section" [formGroup]="whoAreYouForm">
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.FirstName"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.MiddleInitial"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.LastName"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.ApplicantPhoneNumber"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.ConfirmPhoneNumber"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>

            <!-- Text Has Communication Selector - Used to Override Checkbox -->
            <div class="input-scaffold" style="margin-top: 8px">
                <label class="input-label">
                    <span class="input-scaffold__label">Is it OK to text you?<span style="color: red"> *</span></span>
                    <dynamic-field [field]="abstractApplicationWhoAreYouControls.SelectedTextCommunication"
                        [form]="whoAreYouForm" (valueChanges)="selectedTextCommunicationId = $event">
                    </dynamic-field>
                </label>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div *ngIf="!phoneNumbersMatch()">
                <div class="small inside-box-validation ng-star-inserted">
                    Phone number and Confirm phone number do not match.
                </div>
                <br />
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.PlaceOfBirth" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label" [style.margin-bottom]="'-10px'">Date of Birth<span
                            style="color: red">*</span></span>
                    <dynamic-field [field]="abstractApplicationWhoAreYouControls.Dob" [form]="whoAreYouForm">
                    </dynamic-field>
                </label>
            </div>
        </div>

        <div class="form-section-border">
            <h3>Emergency Contact</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.EmergencyContactName"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.EmergencyContactNumber"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Address</h3>
        </div>
        <!-- Form Controls -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.CountryCode"
                    (valueChanges)="setStatesForCountry($event)" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.City" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div *ngIf="countryHasStateCodes()" class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.StateCode" [form]="whoAreYouForm"
                    (controlCreated)="stateControlCreated($event)"></dynamic-field>
            </div>
            <div *ngIf="!countryHasStateCodes()" class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Province" [form]="whoAreYouForm"
                    (controlCreated)="provinceControlCreated($event)"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Address1" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Address2" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Zip" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
        </div>

        <div class="form-section-border">
            <h3>Physical Features</h3>
        </div>
        <!-- Form Controls -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.BodyStructureId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.EyeColorId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.NaturalHairColorId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HairTextureId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.SkinTypeId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Race/Ancestry Information <span class="pull-right align-text-bottom header-small-text">Please mark all
                    that apply only if your genetics are 20% or more of that race/ancestry.</span></h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.RaceId"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="motherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Mother's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect [ngClass]="{ invalid: !motherAncestrySelected && isSaved }" [disabled]="denied"
                    [items]="motherAncestries" placeholder="Select ancestries" [filterMode]="false"
                    (onSelectionChanged)="motherAncestriesChanged($event)" [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false" [autoClose]="true">
                </multiselect>
            </div>
            <div class="input-scaffold" *ngIf="fatherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Father's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect [ngClass]="{ invalid: !fatherAncestrySelected && isSaved }" [disabled]="denied"
                    [items]="fatherAncestries" placeholder="Select ancestries" [filterMode]="false"
                    (onSelectionChanged)="fatherAncestriesChanged($event)" [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false" [autoClose]="true">
                </multiselect>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" *ngIf="getHasOtherAncestry('Mother') || whoAreYou.OtherAncestryMother">
                <br />
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherAncestryMother"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="getHasOtherAncestry('Father') || whoAreYou.OtherAncestryFather">
                <br />
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherAncestryFather"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Educational Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field *ngIf="highSchoolOrAbove()"
                    [field]="abstractApplicationWhoAreYouControls.HighSchoolGpaId" [form]="whoAreYouForm">
                </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field *ngIf="collegeOrAbove()" [field]="abstractApplicationWhoAreYouControls.CollegeMajor"
                    [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field *ngIf="collegeOrAbove()" [field]="abstractApplicationWhoAreYouControls.CollegeGpaId"
                    [form]="whoAreYouForm">
                </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group" style="max-width: 50%">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.CurrentOccupation"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Introduce Yourself</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <p>
                    This section is an opportunity to introduce yourself to potential parents. Although
                    this information you provide is not genetic, it is helpful for a potential parent to
                    connect emotionally with your shared interests, so please be generous and detailed
                    with your responses. - Please do not include any identifying information.
                </p>
            </div>
        </div>
        <br />
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.TalentsAbilities"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Favorites"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.CharacterDescription"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.Goals"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.ExerciseRoutine"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.WhyInterestedInBecomingEggDonor"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.LanguagesSpoken"
                    [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                    accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractApplicationWhoAreYouControls.Submitted"
                    [form]="whoAreYouForm"> </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>