import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IApplicationWhoAreYou } from '../interfaces/application-who-are-you';
import { IBirthControlType } from '../interfaces/birth-control-type';
import { IBodyStructure } from '../interfaces/body-structure';
import { IGpa } from '../interfaces/gpa';
import { IEducationCompletedType } from '../interfaces/education-completed-type';
import { IEyeColor } from '../interfaces/eye-color';
import { IHairTexture } from '../interfaces/hair-texture';
import { INaturalHairColor } from '../interfaces/natural-hair-color';
import { IRace } from '../interfaces/race';
import { ISeriousTraumaticEvent } from '../interfaces/serious-traumatic-event';
import { ISkinType } from '../interfaces/skin-type';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IAncestry } from '@model/interfaces/ancestry';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { SharedFunctionsService } from '../../common/services/shared-functions-service';
import { Input } from '@angular/core';

export interface IApplicationWhoAreYouDynamicControlsParameters {
    formGroup?: string;
    metaData: IPersonalInformationMetaData
    states?: IStateMetaItem[];
    countries?: ICountryMetaItem[];
    hasAcknowledgedWaiver: boolean
}

export class ApplicationWhoAreYouDynamicControls {

    formGroup: string;
    bodyStructures: IBodyStructure[];
    eyeColors: IEyeColor[];
    naturalHairColors: INaturalHairColor[];
    hairTextures: IHairTexture[];
    skinTypes: ISkinType[];
    races: IRace[];
    educationCompletedTypes: IEducationCompletedType[];
    highSchoolGpas: IGpa[];
    collegeGpas: IGpa[];
    birthControlTypes: IBirthControlType[];
    seriousTraumaticEvents: ISeriousTraumaticEvent[];
    ancestries?: IAncestry[];
    states?: IStateMetaItem[];
    countries?: ICountryMetaItem[];
    sharedFunctionsService: SharedFunctionsService;

    Form: IExpandableObject;
    View: IExpandableObject;
    hasAcknowledgedWaiver: boolean;

    constructor(private applicationwhoareyou?: IApplicationWhoAreYou, private donor?: ILoggedIn, additionalParameters?: IApplicationWhoAreYouDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationWhoAreYou';
        this.bodyStructures = additionalParameters && additionalParameters.metaData.BodyStructures || undefined;
        this.eyeColors = additionalParameters && additionalParameters.metaData.EyeColors || undefined;
        this.naturalHairColors = additionalParameters && additionalParameters.metaData.HairColors || undefined;
        this.hairTextures = additionalParameters && additionalParameters.metaData.HairTextures || undefined;
        this.skinTypes = additionalParameters && additionalParameters.metaData.SkinTypes || undefined;
        this.races = additionalParameters && additionalParameters.metaData.Races || undefined;
        this.highSchoolGpas = additionalParameters && additionalParameters.metaData.Gpas || undefined;
        this.collegeGpas = additionalParameters && additionalParameters.metaData.Gpas || undefined;
        this.birthControlTypes = additionalParameters && additionalParameters.metaData.BirthControlTypes || undefined;
        this.seriousTraumaticEvents = additionalParameters && additionalParameters.metaData.SeriousTraumaticEvents || undefined;
        this.ancestries = additionalParameters && additionalParameters.metaData.Ancestries || undefined;
        this.states = additionalParameters && additionalParameters.states || undefined;
        this.countries = additionalParameters && additionalParameters.countries || undefined;
        this.sharedFunctionsService = new SharedFunctionsService();
        this.hasAcknowledgedWaiver = additionalParameters.hasAcknowledgedWaiver || undefined;

        this.Form = {
            Address1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address1',
                name: 'Address1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Address1') && this.applicationwhoareyou.Address1 !== null ? this.applicationwhoareyou.Address1.toString() : '',
            }),
            Address2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address2',
                name: 'Address2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Address2') && this.applicationwhoareyou.Address2 !== null ? this.applicationwhoareyou.Address2.toString() : '',
            }),
            ApplicantPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Applicant Phone Number',
                name: 'ApplicantPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(20)],
                validators: { 'maxlength': 20 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ApplicantPhoneNumber') && this.applicationwhoareyou.ApplicantPhoneNumber !== null ? this.applicationwhoareyou.ApplicantPhoneNumber.toString() : '',
            }),
            ConfirmPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirm Phone Number',
                name: 'ConfirmPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(20)],
                validators: { 'maxlength': 20 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ConfirmPhoneNumber') && this.applicationwhoareyou.ConfirmPhoneNumber !== null ? this.applicationwhoareyou.ConfirmPhoneNumber.toString() : '',
            }),
            BodyStructureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body Structure',
                name: 'BodyStructureId',
                options: this.bodyStructures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.BodyStructureId || null,
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('City') && this.applicationwhoareyou.City !== null ? this.applicationwhoareyou.City.toString() : '',
            }),
            CharacterDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Character Description',
                name: 'CharacterDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CharacterDescription') && this.applicationwhoareyou.CharacterDescription !== null ? this.applicationwhoareyou.CharacterDescription.toString() : '',
            }),
            CollegeGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Gpa',
                name: 'CollegeGpaId',
                options: this.collegeGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.CollegeGpaId || null,
            }),
            CollegeMajor: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Major',
                name: 'CollegeMajor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(100)],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeMajor') && this.applicationwhoareyou.CollegeMajor !== null ? this.applicationwhoareyou.CollegeMajor.toString() : '',
            }),
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Complete') && this.applicationwhoareyou.Complete !== null ? this.applicationwhoareyou.Complete : false,
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(2)],
                validators: { 'maxlength': 2 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CountryCode') && this.sharedFunctionsService.getCountryId(this.applicationwhoareyou.CountryCode, this.countries),
            }),
            CurrentOccupation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Occupation',
                name: 'CurrentOccupation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(100)],
                validators: { 'maxlength': 100 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CurrentOccupation') && this.applicationwhoareyou.CurrentOccupation !== null ? this.applicationwhoareyou.CurrentOccupation.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.applicationwhoareyou && this.applicationwhoareyou.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.DateModified || null,
            }),
            Dob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dob',
                name: 'Dob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.Dob || null,
            }),
            EmergencyContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact',
                name: 'EmergencyContactName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(125)],
                validators: { 'required': true, 'maxlength': 125 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EmergencyContactName') && this.applicationwhoareyou.EmergencyContactName !== null ? this.applicationwhoareyou.EmergencyContactName.toString() : '',
            }),
            EmergencyContactNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact Number',
                name: 'EmergencyContactNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.minLength(10), Validators.maxLength(20)],
                validators: { 'required': true, 'minlength': 10, 'maxlength': 20 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EmergencyContactNumber') && this.applicationwhoareyou.EmergencyContactNumber !== null ? this.applicationwhoareyou.EmergencyContactNumber.toString() : '',
            }), ExerciseRoutine: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exercise Routine',
                name: 'ExerciseRoutine',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ExerciseRoutine') && this.applicationwhoareyou.ExerciseRoutine !== null ? this.applicationwhoareyou.ExerciseRoutine.toString() : '',
            }),
            EyeColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eye Color',
                name: 'EyeColorId',
                options: this.eyeColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.EyeColorId || null,
            }), Favorites: new DynamicField({
                formGroup: this.formGroup,
                label: 'Favorites',
                name: 'Favorites',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Favorites') && this.applicationwhoareyou.Favorites !== null ? this.applicationwhoareyou.Favorites.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('FirstName') && this.donor.CustomOptions.FirstName !== null ? this.donor.CustomOptions.FirstName : '',
            }),
            Goals: new DynamicField({
                formGroup: this.formGroup,
                label: 'Goals',
                name: 'Goals',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Goals') && this.applicationwhoareyou.Goals !== null ? this.applicationwhoareyou.Goals.toString() : '',
            }),
            HairTextureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Texture',
                name: 'HairTextureId',
                options: this.hairTextures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.HairTextureId || null,
            }),
            HasAcknowledgedWaiver: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Acknowledged Waiver',
                name: 'HasAcknowledgedWaiver',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.hasAcknowledgedWaiver,
            }),
            HasTextCommunication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Text Communication',
                name: 'HasTextCommunication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasTextCommunication') && this.applicationwhoareyou.HasTextCommunication !== null ? this.applicationwhoareyou.HasTextCommunication : false,
            }),
            HighSchoolGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'High School Gpa',
                name: 'HighSchoolGpaId',
                options: this.highSchoolGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.HighSchoolGpaId || null,
            }),
            LanguagesSpoken: new DynamicField({
                formGroup: this.formGroup,
                label: 'Languages Spoken',
                name: 'LanguagesSpoken',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('LanguagesSpoken') && this.applicationwhoareyou.LanguagesSpoken !== null ? this.applicationwhoareyou.LanguagesSpoken.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('LastName') && this.donor.CustomOptions.LastName !== null ? this.donor.CustomOptions.LastName : '',
            }),
            MiddleInitial: new DynamicField({
                formGroup: this.formGroup,
                label: 'Initial',
                name: 'MiddleInitial',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(1)],
                validators: { 'maxlength': 1 },
                value: this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('MiddleInitial') && this.donor.CustomOptions.MiddleInitial !== null ? this.donor.CustomOptions.MiddleInitial : '',
            }),
            NaturalHairColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Natural Hair Color',
                name: 'NaturalHairColorId',
                options: this.naturalHairColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.NaturalHairColorId || null,
            }),
            OtherAncestryFather: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Father',
                name: 'OtherAncestryFather',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(250)],
                validators: { 'maxlength': 250 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryFather') && this.applicationwhoareyou.OtherAncestryFather !== null ? this.applicationwhoareyou.OtherAncestryFather.toString() : '',
            }),
            OtherAncestryMother: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Mother',
                name: 'OtherAncestryMother',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(250)],
                validators: { 'maxlength': 250 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryMother') && this.applicationwhoareyou.OtherAncestryMother !== null ? this.applicationwhoareyou.OtherAncestryMother.toString() : '',
            }),
            PlaceOfBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Place Of Birth',
                name: 'PlaceOfBirth',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.Dropdown,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('PlaceOfBirth') && this.applicationwhoareyou.PlaceOfBirth !== null ? this.applicationwhoareyou.PlaceOfBirth.toString() : '',
            }),
            Province: new DynamicField({
                formGroup: this.formGroup,
                label: 'Province/Region/County',
                name: 'Province',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Province') && this.applicationwhoareyou.Province !== null ? this.applicationwhoareyou.Province.toString() : '',
            }),
            RaceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Race',
                name: 'RaceId',
                options: this.races,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.RaceId || null,
            }),
            SkinTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skin Type',
                name: 'SkinTypeId',
                options: this.skinTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationwhoareyou && this.applicationwhoareyou.SkinTypeId || null,
            }),
            Ssn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ssn',
                name: 'Ssn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(44)],
                validators: { 'maxlength': 44 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Ssn') && this.applicationwhoareyou.Ssn !== null ? this.applicationwhoareyou.Ssn.toString() : '',
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State/Province',
                name: 'StateCode',
                options: this.states,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3)],
                validators: { 'maxlength': 3 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('StateCode') && this.sharedFunctionsService.getStateId(this.applicationwhoareyou.CountryCode, this.countries, this.applicationwhoareyou.StateCode, this.states),
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct to the best of my knowledge.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: false,
            }), TalentsAbilities: new DynamicField({
                formGroup: this.formGroup,
                label: 'Talents Abilities',
                name: 'TalentsAbilities',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3500)],
                validators: { 'minlength': 100, 'maxlength': 3500 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('TalentsAbilities') && this.applicationwhoareyou.TalentsAbilities !== null ? this.applicationwhoareyou.TalentsAbilities.toString() : '',
            }),
            WhyInterestedInBecomingEggDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Why Interested In Becoming Egg Donor',
                name: 'WhyInterestedInBecomingEggDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [Validators.minLength(100), Validators.maxLength(3000)],
                validators: { 'minlength': 100, 'maxlength': 3000 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationwhoareyou.WhyInterestedInBecomingEggDonor !== null ? this.applicationwhoareyou.WhyInterestedInBecomingEggDonor.toString() : '',
            }),
            Zip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Zip',
                name: 'Zip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(20)],
                validators: { 'maxlength': 20 },
                value: this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Zip') && this.applicationwhoareyou.Zip !== null ? this.applicationwhoareyou.Zip.toString() : '',
            }),
        };

        this.View = {
            Address1: new DynamicLabel(
                'Address1',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Address1') && this.applicationwhoareyou.Address1 !== null ? this.applicationwhoareyou.Address1.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Address2: new DynamicLabel(
                'Address2',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Address2') && this.applicationwhoareyou.Address2 !== null ? this.applicationwhoareyou.Address2.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApplicantPhoneNumber: new DynamicLabel(
                'Applicant Phone Number',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ApplicantPhoneNumber') && this.applicationwhoareyou.ApplicantPhoneNumber !== null ? this.applicationwhoareyou.ApplicantPhoneNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CharacterDescription: new DynamicLabel(
                'Character Description',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CharacterDescription') && this.applicationwhoareyou.CharacterDescription !== null ? this.applicationwhoareyou.CharacterDescription.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ConfirmPhoneNumber: new DynamicLabel(
                'Confirm Phone Number',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ConfirmPhoneNumber') && this.applicationwhoareyou.ConfirmPhoneNumber !== null ? this.applicationwhoareyou.ConfirmPhoneNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            BodyStructureId: new DynamicLabel(
                'Body Structure',
                getMetaItemValue(this.bodyStructures, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('BodyStructureId') && this.applicationwhoareyou.BodyStructureId !== null ? this.applicationwhoareyou.BodyStructureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            City: new DynamicLabel(
                'City',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('City') && this.applicationwhoareyou.City !== null ? this.applicationwhoareyou.City.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeGpaId: new DynamicLabel(
                'College Gpa',
                getMetaItemValue(this.collegeGpas, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeGpaId') && this.applicationwhoareyou.CollegeGpaId !== null ? this.applicationwhoareyou.CollegeGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeMajor: new DynamicLabel(
                'College Major',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CollegeMajor') && this.applicationwhoareyou.CollegeMajor !== null ? this.applicationwhoareyou.CollegeMajor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Complete: new DynamicLabel(
                'Complete',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Complete') && this.applicationwhoareyou.Complete !== null ? this.applicationwhoareyou.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CountryCode: new DynamicLabel(
                'Country Code',
                this.sharedFunctionsService.getCountryNameByCode(this.applicationwhoareyou.CountryCode, this.countries),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ), Submitted: new DynamicLabel(
                'Submitted',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Submitted') && this.applicationwhoareyou.Submitted !== null ? this.applicationwhoareyou.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CurrentOccupation: new DynamicLabel(
                'Current Occupation',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('CurrentOccupation') && this.applicationwhoareyou.CurrentOccupation !== null ? this.applicationwhoareyou.CurrentOccupation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationwhoareyou && this.applicationwhoareyou.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationwhoareyou && this.applicationwhoareyou.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Dob: new DynamicLabel(
                'Dob',
                this.applicationwhoareyou && this.applicationwhoareyou.Dob || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EmergencyContactName: new DynamicLabel(
                'Emergency Contact',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EmergencyContactName') && this.applicationwhoareyou.EmergencyContactName !== null ? this.applicationwhoareyou.EmergencyContactName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EmergencyContactNumber: new DynamicLabel(
                'Emergency Contact Number',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EmergencyContactNumber') && this.applicationwhoareyou.EmergencyContactNumber !== null ? this.applicationwhoareyou.EmergencyContactNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ), ExerciseRoutine: new DynamicLabel(
                'Exercise Routine',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('ExerciseRoutine') && this.applicationwhoareyou.ExerciseRoutine !== null ? this.applicationwhoareyou.ExerciseRoutine.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),

            EyeColorId: new DynamicLabel(
                'Eye Color',
                getMetaItemValue(this.eyeColors, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('EyeColorId') && this.applicationwhoareyou.EyeColorId !== null ? this.applicationwhoareyou.EyeColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Favorites: new DynamicLabel(
                'Favorites',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Favorites') && this.applicationwhoareyou.Favorites !== null ? this.applicationwhoareyou.Favorites.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('FirstName') && this.donor.CustomOptions.FirstName !== null ? this.donor.CustomOptions.FirstName : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Goals: new DynamicLabel(
                'Goals',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Goals') && this.applicationwhoareyou.Goals !== null ? this.applicationwhoareyou.Goals.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairTextureId: new DynamicLabel(
                'Hair Texture',
                getMetaItemValue(this.hairTextures, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HairTextureId') && this.applicationwhoareyou.HairTextureId !== null ? this.applicationwhoareyou.HairTextureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasTextCommunication: new DynamicLabel(
                'Has Text Communication',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HasTextCommunication') && this.applicationwhoareyou.HasTextCommunication !== null ? this.applicationwhoareyou.HasTextCommunication : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasAcknowledgedWaiver: new DynamicLabel(
                'Has Acknowledged Waiver',
                this.hasAcknowledgedWaiver,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HighSchoolGpaId: new DynamicLabel(
                'High School Gpa',
                getMetaItemValue(this.highSchoolGpas, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('HighSchoolGpaId') && this.applicationwhoareyou.HighSchoolGpaId !== null ? this.applicationwhoareyou.HighSchoolGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            LanguagesSpoken: new DynamicLabel(
                'Languages Spoken',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('LanguagesSpoken') && this.applicationwhoareyou.LanguagesSpoken !== null ? this.applicationwhoareyou.LanguagesSpoken.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('LastName') && this.donor.CustomOptions.LastName !== null ? this.donor.CustomOptions.LastName : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MiddleInitial: new DynamicLabel(
                'Middle Initial',
                this.donor && this.donor.CustomOptions && this.donor.CustomOptions.hasOwnProperty('MiddleInitial') && this.donor.CustomOptions.MiddleInitial !== null ? this.donor.CustomOptions.MiddleInitial : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NaturalHairColorId: new DynamicLabel(
                'Natural Hair Color',
                getMetaItemValue(this.naturalHairColors, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('NaturalHairColorId') && this.applicationwhoareyou.NaturalHairColorId !== null ? this.applicationwhoareyou.NaturalHairColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherAncestryFather: new DynamicLabel(
                'Other Ancestry Father',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryFather') && this.applicationwhoareyou.OtherAncestryFather !== null ? this.applicationwhoareyou.OtherAncestryFather.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherAncestryMother: new DynamicLabel(
                'Other Ancestry Mother',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('OtherAncestryMother') && this.applicationwhoareyou.OtherAncestryMother !== null ? this.applicationwhoareyou.OtherAncestryMother.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PlaceOfBirth: new DynamicLabel(
                'Place Of Birth',
                applicationwhoareyou.PlaceOfBirth ? this.countries.find((country) => country.CountryCode === applicationwhoareyou.PlaceOfBirth).Name : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Province: new DynamicLabel(
                'Province',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Province') && this.applicationwhoareyou.Province !== null ? this.applicationwhoareyou.Province.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ), RaceId: new DynamicLabel(
                'Race',
                getMetaItemValue(this.races, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('RaceId') && this.applicationwhoareyou.RaceId !== null ? this.applicationwhoareyou.RaceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SkinTypeId: new DynamicLabel(
                'Skin Type',
                getMetaItemValue(this.skinTypes, this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('SkinTypeId') && this.applicationwhoareyou.SkinTypeId !== null ? this.applicationwhoareyou.SkinTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ssn: new DynamicLabel(
                'Ssn',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Ssn') && this.applicationwhoareyou.Ssn !== null ? this.applicationwhoareyou.Ssn.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StateCode: new DynamicLabel(
                'State/Province',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('StateCode') && this.applicationwhoareyou.StateCode !== null ? this.applicationwhoareyou.StateCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TalentsAbilities: new DynamicLabel(
                'Talents Abilities',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('TalentsAbilities') && this.applicationwhoareyou.TalentsAbilities !== null ? this.applicationwhoareyou.TalentsAbilities.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            WhyInterestedInBecomingEggDonor: new DynamicLabel(
                'Why Interested In Becoming Egg Donor',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationwhoareyou.WhyInterestedInBecomingEggDonor !== null ? this.applicationwhoareyou.WhyInterestedInBecomingEggDonor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Zip: new DynamicLabel(
                'Zip',
                this.applicationwhoareyou && this.applicationwhoareyou.hasOwnProperty('Zip') && this.applicationwhoareyou.Zip !== null ? this.applicationwhoareyou.Zip.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };
    }
}
