<div class="row" *ngIf="!submitting">
    <div class="col-md-8 col-md-offset-2">
        <div *ngIf="formCreated">
            <div class="form-section-border">
                <h2>Submit Application</h2>
            </div>
            <p>
                Thank you for completing your application to be a donor with The World Egg and Sperm Bank. Please review and
                acknowledge the following waiver
                and upload 4 photos of yourself.
            </p>
            <form class="miles-form padded row application-section" [formGroup]="submissionForm">
                <div class="form-section-border">
                    <h3>Waiver</h3>
                </div>
                <div class="input-scaffold__group">
                    <div class="input-scaffold">
                        <p>
                            As an egg donor you understand that you have waived all parental rights and obligations
                            regarding the financial, physical
                            or emotional well-being of the child.
                        </p>
                        <div *ngIf="showWarning" class="small" style="color: 'red'">Waiver must be acknowledged in order
                            to submit</div>
                    </div>
                    <div class="input-scaffold">
                        <dynamic-field [field]="waiverControl" [overrideForm]="true" [form]="submissionForm"
                            (valueChanges)="onWaiverValueChange($event)">
                        </dynamic-field>
                    </div>
                </div>
                <div class="form-section-border">
                    <h3>Photos</h3>
                </div>
                <p>
                    Please review the
                    <a target="_self" href="../../../assets/Examples of Photos.pdf"
                        download="Examples of Photos.pdf">Photo Instructions Document</a>
                    before uploading photos.
                </p>
                <p *ngIf="photosForUpload.length < 4" class="font-weight-bold text-danger">
                    Upload <strong>{{ 4 - photosForUpload.length }}</strong> more photo{{ photosForUpload.length < 3
                        ? 's' : '' }}. </p>
                        <br />
                        <application-photo-uploader (onQueueChange)="setPhotosForUpload($event)"
                            [applicationId]="applicationIdSubject"></application-photo-uploader>
                        <div class="form-section-border">
                            <h3>Photo ID</h3>
                        </div>
                        <p>Please upload an image of your photo ID.</p>
                        <br />
                        <application-identity-photo-uploader (onQueueChange)="setIdForUpload($event)"
                            [applicationId]="applicationIdSubject"></application-identity-photo-uploader>
                        <div class="form-section-border">
                            <h3>Social Security Number</h3>
                        </div>
                        <div class="input-scaffold" style="width: inherit">
                            <dynamic-field [field]="socialSecurityControl" [overrideForm]="true"
                                [form]="submissionForm"> </dynamic-field>
                            <span>Social Security Number is encrypted upon saving.</span>
                        </div>
                        <div></div>
                        <ng-container *ngIf="denied">
                            <h3 style="text-align: center">Status: Denied</h3>
                        </ng-container>
                        <ng-container *ngIf="!denied">
                            <div class="text-center">
                                <button mt-doubleClickDisabled (doubleClickIsDisabled)="(doubleClickDisabled)"
                                    class="btn button--primary app-saver-button" type="button" (click)="submit()"
                                    [disabled]="!readyToSubmit">
                                    Submit Application
                                </button>
                            </div>
                        </ng-container>
            </form>
        </div>
    </div>
</div>

<div class="row" *ngIf="submitting">
    <div class="center-horizontal-vertical text-center">
        <h2>Submitting Application</h2>
        <p>Please wait, this will take a moment.</p>
        <p>Do not attempt to refresh.</p>
        <div>
            <ngx-skeleton-loader count="3" appearance="circle"></ngx-skeleton-loader>
        </div>
    </div>
</div>

<modal-wrapper *ngIf="showPassed" [options]="modalOptions">
    <h2>Application Submitted Successfully</h2>
    <p>
        Your application was submitted successfully! It is currently under review. <span *ngIf="!showAppointment">If your application is accepted, a member of our team will contact you to schedule an online interview.</span>
    </p>
    <br />
    <p *ngIf="showAppointment">
        In order to continue please schedule an interview appointment below.
    </p>
    <br />
    <br />
    <appointment-scheduling *ngIf="showAppointment" [allowInPerson]="allowInPersonAppointment"></appointment-scheduling>
</modal-wrapper>

<modal-wrapper *ngIf="showReviewModal" [options]="modalOptions">
    <h2>Application Submitted Successfully</h2>
    <p>
        Thank you for completing your Donor Application. Please allow our team 3-4 days to review your application. We
        will reach out to you once your
        application has been reviewed.
    </p>
    <br />
    <button type="button" class="btn btn-success text-center" (click)="logout()">Logout</button>
</modal-wrapper>

<modal-wrapper *ngIf="showFailModal" [options]="modalOptions">
    <h2>Application Submitted Successfully</h2>
    <p>
        Thank you for completing your donor application, unfortunately you do not meet the criteria to move forward with
        the donation process. If you
        have any questions about your application status please reach out to our administrative team.
        <br /><br />
        TWESB Phone/Email:<br />
        +1-877-331-2427<br />
        Arizona +1-602-678-1906<br />

        Donor@twesb.com
    </p>
    <br />
    <button type="button" class="btn btn-success text-center" (click)="logout()">Logout</button>
</modal-wrapper>